<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form">
      <el-form-item style="margin-bottom: 20px" label="广告名称" prop="name">
        <el-input v-model="form.title" size="small" placeholder="请输入广告名称"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="目标平台" prop="platform">
        <el-select v-model="form.platform" placeholder="请选择目标平台" style="width: 100%" size="small">
          <el-option label="APP" value="app"></el-option>
          <el-option label="网页" value="pc"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="位置描述" prop="position">
        <el-input v-model="form.position" size="small" placeholder="请输入位置描述"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="生效时间" prop="growth_value">
        <el-date-picker
            v-model="query_time"
            size="small"
            :default-time="['00:00:00', '23:59:59']"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="timestamp"
            @change="getTimeSection"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否开启" prop="is_active">
        <el-switch
            v-model="form.is_active"
            active-text="启用"
            :active-value="1"
            inactive-text="暂不启用"
            :inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="新窗口打开" prop="is_blank">
        <el-switch
            v-model="form.is_blank"
            active-text="新窗口"
            :active-value="1"
            inactive-text="当前窗口"
            :inactive-value="0"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiCreateUpdateAD} from "@/request/api";

export default {
  name: 'AdminEdit',
  data() {
    return {
      query_time: [],
      form: {
        platform: 'app',
        position: '',
        is_active: 0,
        is_blank: 0,
        title:''
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.form.start_at = event[0] / 1000
        this.form.end_at = event[1] / 1000
      } else {
        this.form.start_at = ''
        this.form.end_at = ''
      }
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
        this.query_time = [Object.assign({}, row).start_at*1000,Object.assign({}, row).end_at*1000]
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.dialogFormVisible = false
    },
    save() {
      apiCreateUpdateAD(this.form).then(res => {
        if(res.code == 200) {
          this.$emit('getData')
          this.close()
        }else{
          this.$message.error(res.message)
        }
      })
    },
  },
}
</script>
