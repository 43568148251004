<template>
  <div class="page">
    <div class="pageFucView">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item label="广告名:">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              placeholder="请输入广告名"
              size="small"
          />
        </el-form-item>
        <el-form-item label="目标平台:">
          <el-select v-model="queryForm.platform" placeholder="请选择目标平台" size="small" clearable>
            <el-option label="APP" value="app"></el-option>
            <el-option label="网页" value="pc"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否开启:">
          <el-select v-model="queryForm.is_active" placeholder="请选择" size="small" clearable>
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" plain size="small">
            查询
          </el-button>
        </el-form-item>
      </el-form>

      <div>
        <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加</el-button>
        <el-button class="button" type="primary" @click="handleDelete" size="small">批量删除</el-button>
      </div>
    </div>


    <el-table
        :data="list"
        @selection-change="setSelectRows"
        style="width: 100%">
      <el-table-column align="center" show-overflow-tooltip type="selection" />
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="title"
          label="广告名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="platform"
          label="目标平台">
      </el-table-column>
      <el-table-column
          align="center"
          prop="position"
          label="展示位置">
      </el-table-column>
      <el-table-column
          align="center"
          label="生效时间">
        <template slot-scope="scope">
          <span>{{scope.row.start_at | formatDate}} - {{scope.row.end_at | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.is_active?'已启用':'未启用'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template #default="{ row }">
          <el-button type="text" @click="handleDetail(row.id)">详情</el-button>
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="queryForm.pageSize" :current-page.sync="queryForm.page" :total="count" @current-change="handleCurrentChange" />
    </div>
    <Edit ref="Edit" @getData="queryForm.page = 1;fetchData()"/>
  </div>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetAD, apiDeleteAD} from "@/request/api";

export default {
  name: 'advertisement',
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: { Edit },
  props: [],
  data() {
    return {
      activeName: '1',
      imgShow: true,
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        platform: '',
        is_active: '',
      },
      list: [],
      ADDetailDailog:false,
      ADDetail:{}
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleDetail(id){
      this.$router.push({path:'/advertise/advertisementDetail',query:{id:id}})
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    batchModification(row) {
      if (row.id) {
        this.$refs['modifyEdit'].showEdit({ id: row.id })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$refs['modifyEdit'].showEdit({ id })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteAD({id:row.id}).then(res => {
            if(res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            }else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除所选项吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            apiDeleteAD({id}).then(res => {
              if(res.code == 200) {
                this.$message.success(res.message)
                this.fetchData()
              }else {
                this.$message.error(res.message)
              }
            })
          })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    fetchData() {
      this.listLoading = true
      apiGetAD(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
